export const RequestMethod = {
    GET:    "get" as const,
    POST:   "post" as const,
    PUT:    "put" as const,
    DELETE: "delete" as const
}

export type UrlItem = {
    url: string;
    method: string;
}

export const URL_TYPE = {
    HELLO :                     {url: '/api/hello',                         method: RequestMethod.GET} as UrlItem,
    PRODUCT_LIST:               {url: '/api/product/list',                  method: RequestMethod.GET} as UrlItem,
    PRODUCT_ITEM:               {url: '/api/product/{id}',                  method: RequestMethod.GET} as UrlItem,
    EDITOR_DEVICES:             {url: '/api/editor/device',                 method: RequestMethod.GET} as UrlItem,
    COMMON_CODE:                {url: '/common/common-code/kind-code',      method: RequestMethod.GET} as UrlItem,
    UPLOAD:                     {url: '/api/common/upload',                 method: RequestMethod.POST} as UrlItem,
    SHOP_INFO:                  {url: '/api/shop/info',                     method: RequestMethod.GET} as UrlItem,
    COUNTRY_LIST:               {url: '/common/country-list',               method: RequestMethod.GET} as UrlItem,
    EXCAHNGE_RATE_LIST:         {url: '/api/common/exchange',               method: RequestMethod.GET} as UrlItem,

    SHOP_MENU:                  {url: '/api/shop/menu-list',                method: RequestMethod.GET} as UrlItem,

    DUPLICATION_CHECK_USERNAME: {url: '/api/join/duplicate-check/username', method: RequestMethod.GET} as UrlItem,
    DUPLICATION_CHECK_NAME:     {url: '/api/join/duplicate-check/name',     method: RequestMethod.GET} as UrlItem,
    REGIST_USER:                {url: '/api/join/regist',                   method: RequestMethod.POST} as UrlItem,
    ACTIVATE_USER:              {url: '/api/join/activate',                 method: RequestMethod.GET} as UrlItem,

    LOGIN:                      {url: '/api/sign/login',                    method: RequestMethod.POST} as UrlItem,
    TOKEN_VALIDATE:             {url: '/api/sign/token-validate',           method: RequestMethod.POST} as UrlItem,
    TOKEN_REFRESH:              {url: '/api/sign/token-refresh',            method: RequestMethod.POST} as UrlItem,
    LOGIN_INFO:                 {url: '/api/sign/login-info',               method: RequestMethod.POST} as UrlItem,

    MY_PAGE:                    {url: '/api/mypage/info',                   method: RequestMethod.GET} as UrlItem,
    UPDATE_MY_INFO:             {url: '/api/mypage/info',                   method: RequestMethod.PUT} as UrlItem,
    PASSWORD_CONFIRM:           {url: '/api/mypage/info/password-confirm',  method: RequestMethod.POST} as UrlItem,
    PASSWORD_CHANGE:            {url: '/api/mypage/info/password-change',   method: RequestMethod.POST} as UrlItem,
    
    VERIFICATION_CODE:          {url: '/api/common/verification-code',      method: RequestMethod.POST} as UrlItem,
    VERIFICATION_CONFIRM:       {url: '/api/common/verification-confirm',   method: RequestMethod.POST} as UrlItem,
    
    ADDRESS_BOOK_PRIMARY:       {url: '/api/mypage/address-book-primary',   method: RequestMethod.GET} as UrlItem,
    ADDRESS_BOOK_LIST:          {url: '/api/mypage/address-book',           method: RequestMethod.GET} as UrlItem,
    ADDRESS_BOOK_ITEM:          {url: '/api/mypage/address-book/{id}',      method: RequestMethod.GET} as UrlItem,
    ADDRESS_BOOK_SAVE:          {url: '/api/mypage/address-book',           method: RequestMethod.POST} as UrlItem,
    ADDRESS_BOOK_UPDATE:        {url: '/api/mypage/address-book',           method: RequestMethod.PUT} as UrlItem,
    ADDRESS_BOOK_DELETE:        {url: '/api/mypage/address-book/{id}',      method: RequestMethod.DELETE} as UrlItem,

    NOTICE_LIST:                {url: '/api/boards/notice/list',            method: RequestMethod.GET} as UrlItem,
    NOTICE_ITEM:                {url: '/api/boards/notice/read/{id}',       method: RequestMethod.GET} as UrlItem,

    CATEGORY_LIST:              {url: '/api/category/list/{categoryId}',    method: RequestMethod.GET} as UrlItem,

    BASKET_LIST:                {url: '/api/member/basket',                 method: RequestMethod.GET} as UrlItem,
    BASKET_ITEMS:               {url: '/api/member/basket-items',           method: RequestMethod.GET} as UrlItem, // 파라미터로 전달된 장바구니 ID를 통해 장바구니 객체 목록을 조회
    BASKET_SAVE:                {url: '/api/member/basket',                 method: RequestMethod.POST} as UrlItem,
    BASKET_UPDATE:              {url: '/api/member/basket',                 method: RequestMethod.PUT} as UrlItem,
    BASKET_DELETE:              {url: '/api/member/basket/{id}',            method: RequestMethod.DELETE} as UrlItem,
    BASKET_DELETE_ITEMS:        {url: '/api/member/basket',                 method: RequestMethod.DELETE} as UrlItem,
    BASKET_DELETE_ALL:          {url: '/api/member/basket/all',             method: RequestMethod.DELETE} as UrlItem,

    ORDER_GET_DELIVERY_PRICE:   {url: '/api/order/delivery-price',          method: RequestMethod.GET} as UrlItem,
    ORDER_LIST:                 {url: '/api/order/sheet',                   method: RequestMethod.GET} as UrlItem,
    ORDER_ITEM:                 {url: '/api/order/sheet/{id}',              method: RequestMethod.GET} as UrlItem,
    ORDER_SAVE:                 {url: '/api/order/sheet',                   method: RequestMethod.POST} as UrlItem,
    ORDER_UPDATE:               {url: '/api/order/sheet',                   method: RequestMethod.PUT} as UrlItem,
    ORDER_REFUND_REQUEST:       {url: '/api/order/refund/{id}',             method: RequestMethod.PUT} as UrlItem,
    ORDER_COMPLETE:             {url: '/api/order/sheet/complete',          method: RequestMethod.PUT} as UrlItem,
    ORDER_COMPLETE_ITEM:        {url: '/api/order/sheet/complete',          method: RequestMethod.GET} as UrlItem,

    SHOP_FRONT_GALLERY:         {url: '/api/shop/front-gallery',            method: RequestMethod.GET} as UrlItem,
    BANNER_LIST:                {url: '/api/shop/banner',                   method: RequestMethod.GET} as UrlItem,
    SHOP_UPDATE_GOODS:          {url: '/api/shop/update-goods',             method: RequestMethod.GET} as UrlItem,  
    SHOP_BOTTOM_GOODS:          {url: '/api/shop/bottom-goods',             method: RequestMethod.GET} as UrlItem,

    FONT_LIST:                  {url: '/api/common/font-resource',          method: RequestMethod.GET} as UrlItem,

    IMAGE_CATEGORY_LIST:        {url: '/api/tool/image-category',           method: RequestMethod.GET} as UrlItem,
    IMAGE_GALLERY_LIST:         {url: '/api/tool/image-gallery',            method: RequestMethod.GET} as UrlItem,

    SELF_DESIGN_RESULT_SAVE:    {url: '/api/tool/self-design-result',       method: RequestMethod.POST} as UrlItem,
    SELF_DESIGN_RESULT_ITEM_SAVE: {url: '/api/tool/self-design-result-item',  method: RequestMethod.POST} as UrlItem,
    SELF_DESIGN_RESULT_LAYER_SAVE:{url: '/api/tool/self-design-result-layer', method: RequestMethod.POST} as UrlItem,
    
}